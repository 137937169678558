.RecContainer {
    width: 200px;
    height: 300px;
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.RecPhoto {
    width: 200px;
    height: 200px;
    border-radius: 10px 10px 10px 10px;
    object-fit: cover;
    overflow: hidden;
    cursor: pointer;
}

.RecTitle {
    margin-left: 10px;
    text-align: left;
    font-size: 16px;
    font-weight: 700;
    color: white;
    padding-top: 5px;
    padding: 5px;
}

.RecSubtitle {
    text-align: left;
    margin-left: 10px;
    color: gray;
}

