.WholePage {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.BookReader {
  display: flex;
  height: calc(100vh - 115px);
}

.ReaderLeftPanel {
  flex-direction: column;
  overflow-y: auto;
}

.sidebar {
  width: 250px;
  height: 100%;
  flex-direction: column;
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  transition: transform 0.3s ease;
  transform: translateX(-80%); /* 默认收起状态 */
}

.player-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  margin-right: 10px;
}

.player-footer {
  margin-left: 60vw;
}

.sidebar.open {
  transform: translateX(0); /* 展开状态 */
}

.ReaderLogo {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 20px;
  margin-bottom: 50px;
  width: 180px;
  cursor: pointer;
}

.ReaderRightPanel {
  flex-grow: 1;
  overflow-y: auto;
}

.BookContent {
  margin-top: 20px;
  margin-bottom: 40px;
  margin-right: 20px;
  font-size: 16px;
  line-height: 1.8;
  flex-wrap: wrap;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.BookTitle {
  font-size: 20pt;
  font-weight: bold;
  cursor: pointer;
  width: fit-content;
  text-align: left;
  @media (max-width: 600px) {
    width: 100%;
    font-size: 14pt;
  }
}

.FileTitle {
  font-size: 30px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}
