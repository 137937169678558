.BookInfoContainer {
  display: flex;
  flex-direction: column;
}

.BookTwoColumns {
  display: flex;
  flex-direction: row;
}

.BookInfoLine1 {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
}

.BookInfoCover {
  margin-left: 20px;
  width: 200px;
}
