.IntroContainer {
  width: 95%;
  display: flex;
  flex-direction: column;
}

.IntroLines {
  border: #bbbbbb 1px solid;
  margin-bottom: 5px;
  width: 100%;
}

.LinkRows {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.TextItem {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 15px;
  color: #453121;
  text-decoration: none;
}

.TextItem:hover {
  color: #007bff;
}

.LinkItem {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 15px;
  color: #453121;
  text-decoration: none;
  cursor: pointer;
}

.LinkItem:hover {
  color: #007bff;
}

.LinkSep {
  color: #111111;
  font-size: 15px;
  margin-bottom: 10px;
}

.IconLines {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.IconImg {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}
