.MyBlock{
    background-color: black;
    width: 100px;
    height: 200px;
    line-height: 200px; /* 设置行高等于高度 */
    text-align: center; /* 水平居中 */
    border: 1px solid #ccc; /* 可选 */
}

.MyLogo {
    background-color: blue;
    width: 50px;
    height: 50px;
}