.RecBookContainer {
  width: 200px;
  height: 280px;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.RecBookPhoto {
  width: 200px;
  height: 240px;
  border-radius: 10px 10px 10px 10px;
  object-fit: cover;
  overflow: hidden;
  cursor: pointer;
}

.RecBookTitle {
  margin-left: 10px;
  text-align: left;
  padding-top: 5px;
}

.RecBookSubtitle {
  text-align: left;
  margin-left: 10px;
}
